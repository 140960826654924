import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignupPage from './pages/SignupPage';
import HomePage from './pages/HomePage';
import HisaabLandingPage from './pages/HisaabLandingPage';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/land" element={<HisaabLandingPage />} />
        <Route path="/start-free" element={<SignupPage />} />
        <Route path="/signup" element={<SignupPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;


