import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore, faBoxes, faUsers, faChartLine, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ArrowRight, PhoneCall, Mail, Menu } from 'lucide-react';

const Header = () => (
  <header className="bg-black text-white py-2 px-4 sticky top-0 z-50">
    <div className="container mx-auto text-center">
      <p>The All-in-One E-commerce Solution for Growing Businesses</p>
    </div>
  </header>
);

const Navigation = () => {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  return (
    <nav className={`py-4 sticky top-0 z-40 transition-colors duration-300 ${scrolled ? 'bg-black' : 'bg-yellow-400'}`}>
      <div className="container mx-auto px-4 flex justify-between items-center">
        <div className={`text-3xl font-medium ${scrolled ? 'text-white' : 'text-black'}`}>Hisaab<span className={scrolled ? 'text-yellow-400' : 'text-orange-500'}>.</span></div>
        <div className="md:hidden">
          <button onClick={() => setMenuOpen(!menuOpen)} className={`${scrolled ? 'text-white' : 'text-black'}`}>
          <Menu size={24} />
          </button>
        </div>
        <div className={`md:flex md:space-x-6 md:items-center ${menuOpen ? 'block' : 'hidden'} absolute md:static top-full left-0 right-0 bg-black md:bg-transparent p-4 md:p-0`}>
          <Link to="/features" className={`block md:inline-block py-2 ${scrolled ? 'text-white hover:text-yellow-400' : 'text-black hover:text-green'} transition-colors`}>Features</Link>
          <Link to="/pricing" className={`block md:inline-block py-2 ${scrolled ? 'text-white hover:text-yellow-400' : 'text-black hover:text-green'} transition-colors`}>Pricing</Link>
          <Link to="/resources" className={`block md:inline-block py-2 ${scrolled ? 'text-white hover:text-yellow-400' : 'text-black hover:text-green'} transition-colors`}>Resources</Link>
          <Link to="/sign-in" className={`block md:inline-block py-2 ${scrolled ? 'text-white hover:text-yellow-400' : 'text-black hover:text-green'} transition-colors`}>Sign in</Link>
          <Link to="/start-free" className={`block md:inline-block mt-4 md:mt-0 ${scrolled ? 'bg-green-800 text-white hover:bg-green-700' : 'bg-green-800 text-white hover:bg-green-700'} px-4 py-2 rounded transition-colors`}>Start free trial</Link>
        </div>
      </div>
    </nav>
  );
};

const SuccessStoryCard = ({ type, title, description, image, name, company }) => (
  <div className="bg-white text-green-800 p-6 rounded-lg">
    <div className="text-sm mb-2 text-grey-800">{type}</div>
    <h3 className="text-xl font-bold mb-2">{title}</h3>
    <p className="mb-4 text-sm">{description}</p>
    <img src={image} alt={name} className="w-full h-32 object-cover rounded-lg mb-4" />
    <p className="font-semibold text-sm">{name}, <span className="text-yellow-400">{company}</span></p>
  </div>
);

const DesktopCarousel = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const childrenArray = React.Children.toArray(children);
  const totalCards = childrenArray.length;
  const cardsPerView = 3;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex + cardsPerView >= totalCards ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? Math.max(0, totalCards - cardsPerView) : prevIndex - 1
    );
  };

  return (
    <div className="relative hidden md:block">
      <div className="overflow-hidden">
        <div
          className="flex transition-transform duration-300 ease-in-out"
          style={{ transform: `translateX(-${(currentIndex * 100) / cardsPerView}%)` }}
        >
          {childrenArray.map((child, index) => (
            <div key={index} className="w-1/3 flex-shrink-0 px-2">{child}</div>
          ))}
        </div>
      </div>
      <button
        onClick={prevSlide}
        className="absolute top-1/2 left-[-40px] transform -translate-y-1/2 bg-green-800 text-white p-2 rounded-full"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <button
        onClick={nextSlide}
        className="absolute top-1/2 right-[-40px] transform -translate-y-1/2 bg-green-800 text-white p-2 rounded-full"
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );
};

const MobileCarousel = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const childrenArray = React.Children.toArray(children);
  const totalCards = childrenArray.length;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalCards);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalCards) % totalCards);
  };

  return (
    <div className="relative md:hidden">
      <div className="overflow-hidden">
        <div
          className="flex transition-transform duration-300 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {childrenArray.map((child, index) => (
            <div key={index} className="w-full flex-shrink-0 px-2">{child}</div>
          ))}
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={prevSlide}
          className="bg-green-800 text-white p-2 rounded-full mr-4"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button
          onClick={nextSlide}
          className="bg-green-800 text-white p-2 rounded-full"
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
};

const Carousel = ({ children }) => (
  <>
    <DesktopCarousel>{children}</DesktopCarousel>
    <MobileCarousel>{children}</MobileCarousel>
  </>
);

const testimonials = [
  {
    type: "Manufacturing",
    title: "Automated Production Line",
    description: "Hisaab Production™ is THE BEST tool for Manufacturers to automate their production line.",
    company: "Popskiland",
    name: "Shubham",
    image: "https://about.hisaab.reev.group/assets/images/popskiland.jpeg"
  },
  {
    type: "E-commerce",
    title: "Comprehensive Solution",
    description: "Obviously, there are so many companies that offer some kind of ecommerce solutions, but when we did the preliminary screening with Hisaab, we quickly thought, 'Yeah, this is the one we're going to go with.'",
    company: "Shree Ambika Fashion",
    name: "Manish Agarwal",
    image: "https://about.hisaab.reev.group/assets/images/shree_ambika_fashion_.jpeg"
  },
  {
    type: "Retail Store",
    title: "Improved Efficiency",
    description: "The inventory management feature alone has saved us countless hours and improved our efficiency tenfold.",
    company: "Seema & Suman",
    name: "Suman Meena",
    image: "https://about.hisaab.reev.group/assets/images/seemaandsuman.jpg"
  },
  {
    type: "Payroll",
    title: "Intuitive System",
    description: "Hisaab Payroll+ is incredibly intuitive.",
    company: "Raghani Tex-Services",
    name: "Rajesh Soni",
    image: "https://about.hisaab.reev.group/assets/images/raghanitradelink.jpg"
  },
  {
    type: "Point of Sale",
    title: "Efficient Sales Management",
    description: "We just finished our busiest season of the year with Hisaab P.O.S.",
    company: "Niharo World",
    name: "Pravesh Kaushik",
    image: "https://about.hisaab.reev.group/assets/images/niharo_mart_3.png"
  }
];

const FeatureSection = ({ icon, title, description, link }) => (
  <div className="flex items-center space-x-8 mb-16 group">
    <div className="w-24 h-24 flex-shrink-0 bg-green-800 rounded-full flex items-center justify-center group-hover:bg-yellow-400 transition-colors duration-300">
      <FontAwesomeIcon icon={icon} className="text-4xl text-white group-hover:text-green-800 transition-colors duration-300" />
    </div>
    <div>
      <h3 className="text-2xl font-semibold text-green-800 mb-2 group-hover:text-yellow-400 transition-colors duration-300">{title}</h3>
      <p className="text-gray-700 mb-2">{description}</p>
      <Link to={link} className="text-green-800 font-semibold hover:text-yellow-400 transition-colors duration-300">Learn more →</Link>
    </div>
  </div>
);
{/* <footer className="bg-green-800 text-white py-8">
    <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
      <div>
        <h4 className="text-xl font-bold mb-4">Hisaab<span className="text-orange-500">.</span></h4>
        <p>Empowering businesses with all-in-one e-commerce solutions</p>
      </div>
      <div>
        <h4 className="text-xl font-bold mb-4">Quick Links</h4>
        <ul>
          <li><Link to="/features" className="hover:text-yellow-400">Features</Link></li>
          <li><Link to="/pricing" className="hover:text-yellow-400">Pricing</Link></li>
          <li><Link to="/resources" className="hover:text-yellow-400">Resources</Link></li>
          <li><Link to="/sign-in" className="hover:text-yellow-400">Sign in</Link></li>
        </ul>
      </div>
      <div>
        <h4 className="text-xl font-bold mb-4">Contact</h4>
        <p>Email: info@hisaab.com</p>
        <p>Phone: (123) 456-7890</p>
      </div>
      <div>
        <h4 className="text-xl font-bold mb-4">Follow Us</h4>
        <div className="flex space-x-4">
          <a href="#" className="text-2xl hover:text-yellow-400">📘</a>
          <a href="#" className="text-2xl hover:text-yellow-400">🐦</a>
          <a href="#" className="text-2xl hover:text-yellow-400">📸</a>
        </div>
      </div>
    </div>
  </footer> */}
const PricingCard = ({ title, price, features, buttonText, highlighted = false }) => {
  return (
    <div className={`bg-white rounded-lg p-6 ${
      highlighted ? 'border-2 border-yellow-400 shadow-xl' : 'border border-gray-200'
    } hover:shadow-lg transition-all duration-300`}>
      <h3 className="text-2xl font-bold mb-4 text-green-800">{title}</h3>
      <p className="text-4xl font-bold mb-4 text-black">{price}<span className="text-xl text-gray-500">/month</span></p>
      <ul className="text-left mb-6 text-gray-700">
        {features.map((feature, index) => (
          <li key={index} className="mb-2 flex items-start">
            <span className="text-yellow-400 mr-2">✓</span>
            {feature}
          </li>
        ))}
      </ul>
      <Link to="/signup" className={`bg-green-800 text-white px-6 py-2 rounded-full font-semibold hover:bg-black transition-colors duration-300 inline-block text-center w-full`}>
        {buttonText}
      </Link>
    </div>
  );
};

const Footer = () => (
  <footer className="bg-green-800 py-8 text-white">
        <div className="max-w-6xl mx-auto px-4 flex flex-col sm:flex-row justify-between items-center">
          <div className="mb-6 sm:mb-0">
            <h4 className="font-bold mb-2 text-center sm:text-left text-white">Product</h4>
            <ul className="space-y-2 text-center sm:text-left">
              <li><a href="#features" className="hover:underline">Features</a></li>
              <li><a href="#pricing" className="hover:underline">Pricing</a></li>
              <li><a href="#testimonials" className="hover:underline">Testimonials</a></li>
            </ul>
          </div>
          <div className="text-center mb-6 sm:mb-0">
            <h2 className="text-3xl font-bold text-white">hisaab<span className="text-yellow-400">.</span></h2>
          </div>
          <div className="text-center sm:text-right">
            <h4 className="font-bold mb-2 text-white">Support</h4>
            <p className="flex items-center justify-center sm:justify-end"><PhoneCall size={16} className="mr-2" /> (+91) 629-194-6212</p>
            <p className="mt-2 flex items-center justify-center sm:justify-end">
              <Mail size={16} className="mr-2" />
              <a href="mailto:hello@hisaab.store" className="hover:underline text-white">hello@hisaab.store</a>
            </p>
            <p className="mt-2">24/7 customer support</p>
          </div>
        </div>
    </footer>
);

const HomePage = () => {
  return (
    <div className="min-h-screen flex flex-col font-['agrandir-medium']">
      <Navigation />
      
      <main className="flex-grow bg-yellow-400">
        <section className="container mx-auto py-16 px-4">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <h1 className="text-4xl md:text-6xl font-bold mb-4 text-black">The easiest way to sell online in India<span className="text-orange-500">.</span></h1>
              <p className="text-lg md:text-xl mb-8 text-gray-900">Manage inventory, customers, HR, and your online store with our powerful platform.</p>
              <div className="mt-14">
                <Link to="/start-free" className="bg-green-800 text-white px-6 py-3 md:px-8 md:py-4 rounded-full text-base md:text-lg font-semibold hover:bg-black hover:text-white transition-colors inline-block">Start your 30-day free trial</Link>
              </div>
            </div>
            <div className="mb-8 md:mb-0">
              <img src="/images/header-1.png" alt="Business Finance" className="w-full h-auto" />
            </div>
          </div>
        </section>

        <section className="bg-[#f8f3e7] py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl md:text-4xl font-bold text-center mb-16 text-green-800">Why Choose <span className="text-black">Hisaab</span><span className="text-yellow-400">.</span>?</h2>
            <div className="grid md:grid-cols-2 gap-8">
              <FeatureSection 
                icon={faStore}
                title="Online Store" 
                description="Create a beautiful online store with our easy-to-use tools. Customize your design, add products, and start selling in minutes."
                link="/online-store"
              />
              <FeatureSection 
                icon={faBoxes}
                title="Inventory Management" 
                description="Keep track of your stock levels, set reorder points, and manage multiple locations with our powerful inventory system."
                link="/inventory-management"
              />
              <FeatureSection 
                icon={faUsers}
                title="Customer Relationship Management" 
                description="Build stronger relationships with your customers. Track interactions, manage support tickets, and personalize your marketing."
                link="/crm"
              />
              <FeatureSection 
                icon={faChartLine}
                title="Analytics and Reporting" 
                description="Make data-driven decisions with our comprehensive analytics. Track sales, customer behavior, and business performance in real-time."
                link="/analytics"
              />
            </div>
          </div>
        </section>

        <div className="bg-[#f8f3e7] py-8">
          <div className="container mx-auto px-4">
            <hr className="border-t border-green-800 opacity-30" />
          </div>
        </div>

        <section className="bg-[#f8f3e7] py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-green-800">Success Stories</h2>
            <Carousel>
              {testimonials.map((testimonial, index) => (
                <SuccessStoryCard 
                  key={index}
                  type={testimonial.type}
                  title={testimonial.title}
                  description={testimonial.description}
                  image={testimonial.image}
                  name={testimonial.name}
                  company={testimonial.company}
                />
              ))}
            </Carousel>
          </div>
        </section>

        <div className="bg-[#f8f3e7] py-8">
          <div className="container mx-auto px-4">
            <hr className="border-t border-green-800 opacity-30" />
          </div>
        </div>

        <section id="pricing" className="bg-[#f8f3e7] py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl md:text-4xl font-bold text-center mb-4 text-green-800">Choose Your Plan</h2>
            <p className="text-center text-gray-700 mb-12">Select the perfect plan for your business needs</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
              <PricingCard 
                title="Starter"
                price="₹9"
                features={[
                  "Basic Accounting",
                  "Employee Management",
                  "Social Media Post Editor",
                  "Catalog Management"
                ]}
                buttonText="Start now"
              />
              <PricingCard 
                title="Basic"
                price="₹7,999"
                features={[
                  "Customizable online store",
                  "Product catalog management",
                  "Shopping cart functionality",
                  "Secure payment gateway integration",
                  "Order management system",
                  "Customer account creation",
                  "Mobile-responsive design",
                  "Basic SEO tools",
                  "Inventory tracking",
                  "Simple analytics and reporting"
                ]}
                buttonText="Get started"
              />
              <PricingCard 
                title="Pro"
                price="₹14,999"
                features={[
                  "All Basic features",
                  "Advanced reporting",
                  "Multi-channel selling",
                  "Android & iOS mobile app",
                  "SMS & Email campaign"
                ]}
                buttonText="Get started"
                highlighted={true}
              />
              <PricingCard 
                title="Enterprise"
                price="Custom"
                features={[
                  "All Pro features",
                  "Custom integrations",
                  "Dedicated account manager",
                  "24/7 priority support"
                ]}
                buttonText="Contact sales"
              />
            </div>
          </div>
        </section>

        <section className="py-8">
          <div className="text-center bg-yellow-400 p-8 rounded-lg">
            <h3 className="text-2xl font-bold mb-4">Ready to grow your business?</h3>
            <p className="mb-6">Yes! Because we know how to make it large ;)</p>
            <button className="bg-black text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-gray-800 transition-colors inline-block">
              <a href="https://wa.me/916291946212" target="_blank" rel="noopener noreferrer" className="flex items-center">
                Start your free trial <ArrowRight className="ml-2" size={20} />
              </a>
            </button>
          </div>
        </section>
        
        
      </main>

      <Footer />
    </div>
  );
};

export default HomePage;
