import React, { useEffect, useState } from 'react';
import { ShoppingCart, Users, CreditCard, Package, ArrowRight, PhoneCall, Mail, Menu, X } from 'lucide-react';
import { Link } from 'react-router-dom';
const HisaabLandingPage = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    const handleSmoothScroll = (e) => {
      e.preventDefault();
      const targetId = e.target.getAttribute('href');
      const targetElement = document.querySelector(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    };

    const links = document.querySelectorAll('a[href^="#"]');
    links.forEach(link => {
      link.addEventListener('click', handleSmoothScroll);
    });

    return () => {
      links.forEach(link => {
        link.removeEventListener('click', handleSmoothScroll);
      });
    };
  }, []);
  return (
    <div className="bg-white min-h-screen font-sans">
      <header className="bg-yellow-400 py-4 px-6 fixed top-0 left-0 right-0 z-50">
        <nav className="flex justify-between items-center max-w-6xl mx-auto">
          <h1 className="text-black text-2xl font-bold">Hisaab</h1>
          <div className="hidden sm:flex items-center space-x-6">
            <a href="#features" className="text-black hover:text-yellow-700 font-medium">Features</a>
            <a href="#pricing" className="text-black hover:text-yellow-700 font-medium">Pricing</a>
            <a href="#testimonials" className="text-black hover:text-yellow-700 font-medium">Testimonials</a>
            <Link to="/signup" className="bg-black text-white px-5 py-2 rounded-full text-sm font-semibold hover:bg-gray-800 transition-colors inline-block">
              Start free trial
            </Link>
          </div>
          <button className="sm:hidden" onClick={() => setIsDrawerOpen(true)}>
            <Menu size={24} />
          </button>
        </nav>
      </header>
      <DrawerMenu isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />

      <main className="max-w-6xl mx-auto mt-24 sm:mt-32 px-4">
        <section id="features" className="text-center">
          <h2 className="text-3xl sm:text-5xl font-bold text-black mb-4">Simplify Your Business with Hisaab</h2>
          <p className="text-lg sm:text-xl text-gray-700 mb-6">Seamlessly integrate e-commerce, accounting, CRM, and inventory management in one powerful platform</p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
            <FeatureCard 
              icon={<ShoppingCart size={32} />}
              title="E-commerce" 
              description="Build and manage your online store with ease"
              color="bg-blue-200"
            />
            <FeatureCard 
              icon={<CreditCard size={32} />}
              title="Accounting" 
              description="Streamline your financial management and reporting"
              color="bg-green-200"
            />
            <FeatureCard 
              icon={<Users size={32} />}
              title="CRM" 
              description="Manage customer relationships and boost loyalty"
              color="bg-yellow-200"
            />
            <FeatureCard 
              icon={<Package size={32} />}
              title="Inventory Management" 
              description="Track and optimize your stock levels efficiently"
              color="bg-purple-200"
            />
          </div>
          <Link to="/signup" className="bg-black text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-gray-800 transition-colors inline-block">
            Start your 30-day free trial
          </Link>
        </section>

        <hr className="my-16 border-t border-gray-300" />


        <section id="pricing" className="mt-16">
          <h2 className="text-3xl font-bold text-center mb-8">Choose Your Plan</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            <PricingCard 
              title="Free"
              price="₹0"
              features={[
                "Accounting",
                "Employee Management",
                "Manufacturing Management"
              ]}
              buttonText="Start for free"
              buttonColor="bg-green-500"
            />
            <PricingCard 
              title="Basic"
              price="₹20k"
              features={[
                "Website builder",
                "Accounting",
                "Inventory management",
                "HR management",
                "Payroll system",
                "Manufacturing",
                "CRM",
                "Employee payslip",
                "Push notifications"
              ]}
              buttonText="Get started"
              buttonColor="bg-orange-400"
            />
            <PricingCard 
              title="Pro"
              price="₹30k"
              features={[
                "All Basic features",
                "Advanced reporting",
                "Multi-channel selling",
                "Android & iOS mobile app",
                "SMS & Email campaign"
              ]}
              buttonText="Get started"
              buttonColor="bg-purple-800"
              highlighted={true}
            />
            <PricingCard 
              title="Enterprise"
              price="Custom"
              features={["All Pro features", "Custom integrations", "Dedicated account manager"]}
              buttonText="Contact sales"
              buttonColor="bg-green-800"
            />
          </div>
        </section>

        <hr className="my-16 border-t border-gray-300" />

        <section id="testimonials" className="mt-16">
        <h2 className="text-3xl font-bold text-center mb-8">Trusted by Businesses Worldwide</h2>
        <TestimonialSection />
      </section>

        <hr className="my-16 border-t border-gray-300" />

        <section className="mt-16 bg-yellow-400 p-8 rounded-lg">
          <h3 className="text-2xl font-bold mb-4">Ready to grow your business?</h3>
          <p className="mb-4">Yes! Because we know how to make it large ;)</p>
          <button className="bg-black text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-gray-800 transition-colors flex items-center mx-auto">
            <a href="https://wa.me/916291946212" target="_blank" rel="noopener noreferrer" className="bg-black text-white text-lg font-semibold hover:bg-gray-800 transition-colors flex items-center mx-auto inline-block">
              Start your free trial <ArrowRight className="ml-2" size={20} />
            </a>
          </button>
          
        </section>
      </main>

      <footer className="bg-green-800 mt-16 py-8 text-white">
        <div className="max-w-6xl mx-auto px-4 flex flex-col sm:flex-row justify-between items-center">
          <div className="mb-6 sm:mb-0">
            <h4 className="font-bold mb-2 text-center sm:text-left">Product</h4>
            <ul className="space-y-2 text-center sm:text-left">
              <li><a href="#features" className="hover:underline">Features</a></li>
              <li><a href="#pricing" className="hover:underline">Pricing</a></li>
              <li><a href="#testimonials" className="hover:underline">Testimonials</a></li>
            </ul>
          </div>
          <div className="text-center mb-6 sm:mb-0">
            <h2 className="text-3xl font-bold">hisaab.</h2>
          </div>
          <div className="text-center sm:text-right">
            <h4 className="font-bold mb-2">Support</h4>
            <p className="flex items-center justify-center sm:justify-end"><PhoneCall size={16} className="mr-2" /> (+91) 629-194-6212</p>
            <p className="mt-2 flex items-center justify-center sm:justify-end">
              <Mail size={16} className="mr-2" />
              <a href="mailto:hello@hisaab.store" className="hover:underline">hello@hisaab.store</a>
            </p>
            <p className="mt-2">24/7 customer support</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

const DrawerMenu = ({ isOpen, onClose }) => {
  return (
    <div className={`fixed inset-y-0 left-0 z-50 w-64 bg-white shadow-lg transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}>
      <div className="flex justify-end p-4">
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          <X size={24} />
        </button>
      </div>
      <nav className="flex flex-col space-y-4 p-4">
        <a href="#features" className="text-black hover:text-yellow-700" onClick={onClose}>Features</a>
        <a href="#pricing" className="text-black hover:text-yellow-700" onClick={onClose}>Pricing</a>
        <a href="#testimonials" className="text-black hover:text-yellow-700" onClick={onClose}>Testimonials</a>
        <Link to="/signup" className="bg-black text-white px-4 py-2 rounded-full text-sm font-semibold hover:bg-gray-800 transition-colors inline-block text-center" onClick={onClose}>
          Start free trial
        </Link>
      </nav>
    </div>
  );
};

const FeatureCard = ({ icon, title, description, color }) => {
  return (
    <div className={`${color} p-6 rounded-lg text-center`}>
      <div className="text-black mb-4 flex justify-center">{icon}</div>
      <h3 className="text-xl font-semibold text-black mb-2">{title}</h3>
      <p className="text-gray-700">{description}</p>
    </div>
  );
};

const PricingCard = ({ title, price, features, buttonText, buttonColor, highlighted = false }) => {
  return (
    <div className={`border border-gray-200 rounded-lg p-6 ${
      highlighted ? 'bg-purple-50' : 
      title === 'Enterprise' ? 'bg-green-100' : 
      title === 'Basic' ? 'bg-orange-50' : 
      title === 'Free' ? 'bg-green-50' :
      ''
    } hover:shadow-lg transition-shadow`}>
      <h3 className="text-2xl font-bold mb-4">{title}</h3>
      <p className="text-4xl font-bold mb-4">{price}<span className="text-xl text-gray-500">/month</span></p>
      <ul className="text-left mb-6">
        {features.map((feature, index) => (
          <li key={index} className="mb-2">✓ {feature}</li>
        ))}
      </ul>
      <Link to="/signup" className={`${buttonColor} text-white px-6 py-2 rounded-full font-semibold hover:opacity-90 transition-opacity inline-block text-center`}>
        {buttonText}
      </Link>
    </div>
  );
};


const TestimonialSection = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const testimonials = [
    {
      quote: "Hisaab Production™ is THE BEST tool for Manufacturers to automate their production line.",
      company: "Popskiland",
      owner: "Shubham",
      image: "https://about.hisaab.reev.group/assets/images/popskiland.jpeg"
    },
    {
      quote: "Obviously, there are so many companies that offer some kind of ecommerce solutions, but when we did the preliminary screening with Hisaab, we quickly thought, ‘Yeah, this is the one we’re going to go with.'",
      company: "Shree Ambika Fashion",
      owner: "Manish Agarwal",
      image: "https://about.hisaab.reev.group/assets/images/shree_ambika_fashion_.jpeg"
    },
    {
      quote: "The inventory management feature alone has saved us countless hours and improved our efficiency tenfold.",
      company: "Seema & Suman",
      owner: "Suman Meena",
      image: "https://about.hisaab.reev.group/assets/images/seemaandsuman.jpg"
    },
    {
      quote: "Hisaab Payroll+ is incredibly intuitive.",
      company: "Raghani Tex-Services",
      owner: "Rajesh Soni",
      image: "https://about.hisaab.reev.group/assets/images/raghanitradelink.jpg"
    },
    {
      quote: "We just finished our busiest season of the year with Hisaab P.O.S.",
      company: "Niharo World",
      owner: "Pravesh Kaushik",
      image: "https://about.hisaab.reev.group/assets/images/niharo_mart_3.png"
    }
  ];

  const nextTestimonial = () => {
    setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentTestimonial((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <div className="flex flex-col max-w-6xl mx-auto px-4">
      <div className="flex flex-col lg:flex-row items-center justify-between">
        <div className="lg:w-1/2 lg:pr-8 mb-8 lg:mb-0">
          <blockquote className="text-2xl sm:text-3xl font-bold mb-10">
            "{testimonials[currentTestimonial].quote}"
          </blockquote>
          <p className="text-xl font-bold">{testimonials[currentTestimonial].company}</p>
          <p className="text-gray-600">{testimonials[currentTestimonial].owner} — Owner</p>
          <div className="mt-4 flex">
            <button onClick={prevTestimonial} className="mr-2 w-10 h-10 rounded-full border-2 border-black flex items-center justify-center">
              ←
            </button>
            <button onClick={nextTestimonial} className="w-10 h-10 rounded-full border-2 border-black flex items-center justify-center">
              →
            </button>
          </div>
        </div>
        <div className="md:w-1/3">
          <img
            src={testimonials[currentTestimonial].image}
            alt={`${testimonials[currentTestimonial].company} owners`}
            className="rounded-lg shadow-lg w-full h-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default HisaabLandingPage;